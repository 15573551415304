// *
// 存放 使用者 帳號及權限 資料。
//*
import React, { createContext, useState, useEffect } from 'react';
import { AuthProvider } from '@oneclass/ui-components';
import { IDENTITY } from 'constants/identity';

export const UserProviderContext = createContext(() => { });
export const Provider = ({ children }) => {

  const [userData, setUserData] = useState({
    userInfo: {}, // 存放使用者相關資訊
    paperConfig: [] // 組卷基本資料
  });
  const { useAuthDispatch, useAuthState } = AuthProvider;
  const authState = useAuthState();

  const userType = (identity) => {
    return IDENTITY.GENERAL_THEME.indexOf(identity) !== -1 ? 'General' : 'Premium';
  };

  useEffect(() => {
    if ((authState.value === 'loggedIn' || authState.value === 'settingEduSubject')) {
      if (authState.context.userDetails?.userStatus) {
        const { userDetails, parameterData } = authState.context;
        const { onePaperIdentity } = userDetails;
        const { paperConfig } = parameterData?.dynamics;
        const { organization, identity, name } = userDetails?.userStatus;

        const isPro = userType(onePaperIdentity) === 'Premium';

        // if (!isPro) {
        //   window.location.href = 'http://localhost:3000';
        // }
        setUserData({
          userInfo: {
            ...userDetails,
            // organizationType: organization?.type,
            identity,
            name,
            // 前端判斷是否為 專業版（後端也有傳值）
            userType: userType(onePaperIdentity),
            isPro,
          },
          paperConfig
        });
      }
    }
  }, [authState.value]);

  useEffect(() => {
    setTimeout(() => {
      if (userData?.userInfo?.userType && userData.userInfo.userType !== 'Premium') {
        window.location.href = process.env.REACT_APP_NANI_PAPER;
      }
    }, 0);
  }, [userData]);


  const userProviderChange = (value) => {
    setUserData(value);
  };

  const value = { userData, userProviderChange };

  return (
    <UserProviderContext.Provider value={value}>
      {children}
    </UserProviderContext.Provider>
  );
};

const UserProvider = {};
UserProvider.Provider = Provider;
UserProvider.UserProviderContext = UserProviderContext;

export default UserProvider;


